import React, { Component } from 'react';
import WallItem from './wall-item.js';
import WallLoader from './wall-loader.js';
import './wall-container.css';
import ItemOverlay from './item-overlay';
import EditOptions from './edit-options';
import EditManager from '../../logic/edit-manager';

import Overlay from '../common/overlay';
import EditCampaignContent from './edit-campaign-content';
import EditCampaignItemsContent from './edit-campaign-items-content';
import EditGroupContent from './edit-group-content';
import CampaignContent from './campaign-content';

import ShareThanksContent from './share-thanks-content';

import CreatePost from '../post/create-post';

import {PullToRefresh, PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";


class WallContainer extends Component {

  constructor(props) {
    super(props);

      this.state = {
        items: props.feedItems,
        currentPage: 0,
        itemsPerPage: 19,
        selectedItem: null,
        loading: false,
        editMode: /*this.props.dataLoad.userData.adminLevel == 1*/false,
        editButtonsEnabled: false,
        overlayActive: false,
        editManager: new EditManager(),
        activeShare: null,
        activeOptions: null,
        activeMenu: true,
        activeLogin: false,
      };

      this.boundedScroll = null;
      this.wallContainerRef = React.createRef();

      this.pullRef = React.createRef();
      this.overlayContent = null;
      this.itemOverlayFooter = null;

      this.autoRefresh = this.props.dataLoad.globalSettings.wallReload > 0;
      this.autoRefreshTime = this.props.dataLoad.globalSettings.wallReload;
      this.autoRefreshTimeout = null;

      this.autoHideMenu = this.props.dataLoad.globalSettings.navigationBar || this.props.dataLoad.globalSettings.navigationBarHideCompletely == false;


      this.autoRefreshListener = null;
      this.autoHideMenuListener = null;

      this.mouseMove = () => {};


      this.shareFeed = null;
      this.shareItem = null;

      this.touchPosY = 0;
  }


  componentDidMount = () => {
      if(this.autoRefresh) {
        this.enableAutoRefresh();
      }
      if(this.autoHideMenu) {
        this.enableMenuAutoHide();
      }

      if(this.props.dataLoad.globalSettings.navigationBarHideCompletely == true) {
        this.hideMenu();
      }
  }

  componentWillUnmount = () => {
    this.disableMenuAutoHide();
    this.disableAutoRefresh();
  }


  componentDidUpdate = (prevProps, prevState) => {
      if(this.state.editMode !== prevState.editMode) {
          if(this.state.editMode === true) {
            if(this.autoRefresh === true) {
                this.disableAutoRefresh();
            } 
            if(this.autoHideMenu === true) {
              this.disableMenuAutoHide();
            }
            
          } else {
            if(this.autoRefresh === true) {
                this.enableAutoRefresh();
            }
            if(this.autoHideMenu === true) {
                this.enableMenuAutoHide();
            }
          }
      }
  }


  restartAutoBehaviors = () => {
    this.restartAutoRefresh();
    this.restartAutoHideMenu();
  }

  restartAutoHideMenu = () => {
    clearTimeout(this.autoHideMenuTimeout);
    this.autoHideMenuTimeout = setTimeout(this.hideMenu, 5000);
    if(this.state.activeMenu === false) {
      this.showMenu();
    }
  }

  enableAutoRefresh = () => {
      this.autoRefresh = true;
      this.autoRefreshTimeout = setTimeout(this.refresh, this.autoRefreshTime);
      document.body.addEventListener("mousemove", this.restartAutoRefresh);

      //console.log("enabled autorefresh at "+this.autoRefreshTime / 1000+"s rate");
  }


  enableMenuAutoHide = () => {
    this.autoHideMenu = true;
    this.autoHideMenuTimeout = setTimeout(this.hideMenu, 5000);
    document.body.addEventListener("mousemove", this.restartAutoHideMenu);
    //console.log("enabled autohide at 5s rate");
  }

  hideMenu = () =>  {
      this.setState({activeMenu: false});
      this.props.onMenuHidden();
  }

  showMenu = () => {
    this.setState({activeMenu: true});
    this.props.onMenuShown();
  }

  disableMenuAutoHide = (permanent) => {
    clearTimeout(this.autoHideMenuTimeout);
    document.body.removeEventListener("mousemove", this.restartAutoHideMenu);
    this.showMenu();
  }


  disableAutoRefresh = () => {
    clearTimeout(this.autoRefreshTimeout);
    document.body.removeEventListener("mousemove", this.restartAutoRefresh);

    //console.log("disabled autorefresh");
  }

  restartAutoRefresh = () => {
    clearTimeout(this.autoRefreshTimeout);
    this.autoRefreshTimeout = setTimeout(this.refresh, this.autoRefreshTime);
  }


  onItemClicked = (data) => {
      this.disableAutoRefresh();
      this.setState({selectedItem: data, activeShare: null, activeOptions: null});
  }

  onCloseItemOverlay = event => {
    
    this.setState({selectedItem: null});
    this.itemOverlayFooter = null;
    if(this.autoRefresh) {
        this.restartAutoRefresh();
    }


  }


  onWindowScroll = event => {
    if(this.boundedScroll) {
      this.boundedScroll(event.target.scrollTop, event.target.scrollHeight);
    }
    if(!this.state.editMode) {
      if(this.autoRefresh === true) {
          this.restartAutoRefresh();
      }
      if(this.autoHideMenu === true) {
          this.restartAutoHideMenu();
      }
    }
  }


  bindScroll = scrollCallback => {
    this.boundedScroll = scrollCallback;
  }


  nextPage = () => {
      return this.props.nextPage(this.state.editMode).then(res => {
        this.setState({items: this.state.items.concat(res)});
        return res;
      });
  }


  refresh = () => {



      return this.setState({loading: true, selectedItem: null}, () => {
          if(this.state.editMode === true) {
            this.state.editManager.reset();
          }
          this.props.dataLoad.refresh(this.state.editMode).then((res, error) => {
              if(res !== undefined) {
                console.log(error);
                console.log("GOt response");
                this.setState({items: res, loading: false});
                if(this.wallContainerRef.current !== null) {
                  this.wallContainerRef.current.scrollTop = 0;
                }
              } else {
                console.log("Go anormal response");
              }
              return true;
          }).catch((e) => { console.log(e) });

      });

  }

  refreshPromise = () => {
    return new Promise((resolve, reject) => {
        this.refresh();
        resolve();
    });
  }

  toggleEdit = () => {
      this.setState({editMode: !this.state.editMode}, this.refresh);
  }


  toggleEditWithItem = (item) => {
    this.state.editManager.selectedItems = [item];
    this.setState({editMode: true}, () => {
        this.updateEditButtonsState();
         this.setState({loading: true, selectedItem: null});
         this.props.dataLoad.refresh(this.state.editMode).then(res => {

            this.setState({items: res, loading: false});
            if(this.wallContainerRef.current !== null) {
              this.wallContainerRef.current.scrollTop = 0;
            }
            return true;
        });
        //this.refresh();
    });

  }


  editOptions = () => {
      if(this.state.editMode === true) {
        return (
            <EditOptions dataLoad={this.props.dataLoad} onCloseEdit={this.toggleEdit} buttonsEnabled={this.state.editButtonsEnabled} reset={this.resetEditOptions} onClickCampaign={this.onClickCampaign}  onChangeItemsDisplay={this.onChangeItemsDisplay} onClickGroup={this.onClickGroup}/>
          )
      }
      return "";
  }




  onEditItemSelectionChange = (item, selected) => {
    this.state.editManager.onItemToggle(item, selected);
    this.updateEditButtonsState();

  }
  updateEditButtonsState = () => {
    if(this.state.editManager.selectedItems.length > 0) {
        this.setState({editButtonsEnabled: true});
      } else {
        this.setState({editButtonsEnabled: false});
      }
  }


  resetEditOptions = () => {
    this.state.editManager.reset();
    this.updateEditButtonsState();
  }


  onClickCampaign = () => {
      this.overlayContent = <EditCampaignContent dataLoad={this.props.dataLoad} cancel={this.hideOverlay} showCampaignItems={this.showCampaignItems} />;
      this.showOverlay();
  }


  onClickGroup = () => {
      this.overlayContent = <EditGroupContent dataLoad={this.props.dataLoad} editManager={this.state.editManager} cancel={this.hideOverlay} refresh={this.refresh} />;
      this.showOverlay();
  } 


  onClickShowCampaign = (item) => {
      this.overlayContent = <CampaignContent dataLoad={this.props.dataLoad} item={item} close={this.hideOverlay} removeItem={this.removeItemFromCampaign} />
      this.showOverlay();
  }



  removeItemFromCampaign = (item, campaign) => {
    console.log(campaign);
    this.props.dataLoad.removeItemFromCampaign(item, campaign).then((res) => {
        item.campaign.id = 0;
        this.hideOverlay();
    }).catch((error) => {
      alert("erreur : "+error);
    });
  }


  onChangeItemsDisplay = (hide) => {
    this.setState({loading: true});
      let items = this.state.editManager.selectedItems.map((item) => {
              return this.state.items.find((findItem) => { return item.id === findItem.id});
      });

      this.props.dataLoad.displayItems(items, hide).then(() => {
          this.resetEditOptions();
          //this.refresh();
          this.setState({loading: false});
      });
  }


  showCampaignItems = (campaign) => {
      this.overlayContent = <EditCampaignItemsContent dataLoad={this.props.dataLoad} campaign={campaign}  cancel={this.hideOverlay} refresh={this.refresh} selectedItems={this.state.editManager.selectedItems}/>;
      this.showOverlay();
  }


  showOverlay = (content) => {
      this.setState({overlayActive: true, selectedItem: null});
      this.itemOverlayFooter = null;
  }

  hideOverlay = () => {
      console.log("hide overlay");
      this.setState({overlayActive: false});
  }


  getOverlay = () => {
      if(this.state.overlayActive) {
        return <Overlay content={this.overlayContent} exit={this.hideOverlay} rounded={true} />
      } else {
        return "";
      }
  }


  showCreatePostModal = () => {
      this.overlayContent = (<CreatePost dataLoad={this.props.dataLoad} exit={this.onPostCreated} />);
      this.setState({overlayActive: true});
  }

  onPostCreated = (points, item, imageOverride) => {
      if(imageOverride !== null) {
        item.imageURL = imageOverride;
      }
      this.showShareThanks({repost: {points: points}}, item, null);
  }


  getLoginOverlay = () => {
    if(this.state.activeLogin) {
        window.closeLoginPopup = this.closeLoginPopupAndReload;
        return <Overlay content={this.getLoginOverlayContent()} clean={true} exit={this.hideLoginOverlay}/>
    } else {
      return null;
    }
  }


  hideLoginOverlay = () => {
    this.setState({activeLogin:false});
  }



  getLoginOverlayContent = () => {
    let iframeUrl = process.env.REACT_APP_BASE_URL+"/login20?lng="+this.props.dataLoad.languageManager.currentLanguage+"&bearer="+encodeURI(this.props.dataLoad.apiBearer);
    return (<iframe className="login-frame" title="login" src={iframeUrl} width="320" height="480" scrolling="no" />);
  }


  shareClicked = (item, network, footer) => {
    console.log("wall share");
    console.log(item);
    console.log(footer);
    this.itemOverlayFooter = footer;
    this.setState({selectedItem: item});
  }

  closeShare = (id) => {
      if(id===undefined || (id !== undefined && id === this.state.activeShare)) {
          this.setState({activeShare: null});
      }
      
  }

  closeOptions = (id) => {
    if(id===undefined || (id !== undefined && id === this.state.activeOptions)) {
          this.setState({activeOptions: null});
      }
 
  }

  presentLogin = () => {

    this.setState({activeLogin: true});

  }



  closeLoginPopupAndReload = () => {
      window.location.reload();
  }

  doShare = (iframeUrl, item, feed) => {


      //this.overlayContent = <iframe title="share" src={iframeUrl} />
      //this.showOverlay();
      //this.showShareThanks({repost: {points: 10}}, item, feed);

      this.shareItem = item;
      this.shareFeed = feed;
      switch (feed.feedTypeId) {
          case 4:
              if(feed.actionType == 2) {
                window.shareDialog.likeTwitter(item.id);
              } else if(feed.actionType == 3) {
                window.shareDialog.openTwitter(item.id);
              } else if(feed.actionType == 4) {
                window.shareDialog.retweetTwitter(item.id);
              }
              break;
          case 5:
              window.shareDialog.openFacebook(item.id);
              break;

          case 6:
              window.shareDialog.openLinkedIn(item.id);
              break;

          case 11:
            window.shareDialog.openEmail(item.id);
            break;

          case 12:
            window.shareDialog.openWhatsApp(item.id);
            break;

          case 13:
            window.shareDialog.openFacebookMessenger(item.id);
            break;

      }
  }

  showShareThanks = (data, item, feed) => {
    console.log("share shx", data, item, feed);
      this.overlayContent = this.renderShareThanksContent(data, item, feed);
      this.showOverlay();
  }

  renderShareThanksContent = (data, item, feed) => {
      return <ShareThanksContent dataLoad={this.props.dataLoad} feed={feed} data={data} item={item} presentLogin={this.presentLogin} onClose={this.hideOverlay} />
  }


  onCancelShare = () => {
      this.itemOverlayFooter = null;
      this.forceUpdate();
  }


  renderItemOverlay = () => {
      return <ItemOverlay item={this.state.selectedItem} footer={this.itemOverlayFooter} onClose={this.onCloseItemOverlay} dataLoad={this.props.dataLoad} doShare={this.doShare} toggleEdit={this.toggleEditWithItem} onCampaignClick={this.onClickShowCampaign} repostCallback={this.repostCallback} onShareClicked={this.shareClicked} onCancelShare={this.onCancelShare} editable={true} refreshWall={this.refresh} />
  }



  repostCallback = (data) => {
   this.hideOverlay();
   this.showShareThanks(data, this.shareItem, this.shareFeed);
  }


  shareActivate = (wallItemId) => {
      this.setState({activeShare: wallItemId, activeOptions: null});
  }

  optionsActivate = (wallItemId) => {
    this.setState({activeShare: null, activeOptions: wallItemId});
  }

  handleTouchStart = (event) => {
    console.log("touch start");
    event.persist();
    this.pullRef.current.style.transitionDuration  = '0s';
    this.touchPosY = event.changedTouches[0].clientY;
  }

  handleTouchMove = (event) => {
    event.persist();

    this.pullRef.current.style.transitionDuration  = '0s';

    var touchobj = event.changedTouches[0];

    console.log("Touch move "+(this.touchPosY)+"/"+touchobj.clientY+"/"+(touchobj.clientY - this.touchPosY));

    if(this.wallContainerRef.current.scrollTop === 0 && (touchobj.clientY-this.touchPosY) > 0) {

        let height = parseInt(this.pullRef.current.clientHeight) + parseInt(touchobj.clientY-this.touchPosY);
        console.log(height);
        this.pullRef.current.style.height = height+'px';
        this.touchPosY = touchobj.clientY;
    }
  }

  handleTouchEnd = (event) => {
      event.persist();
      if(this.pullRef.current.clientHeight > 0) {
        this.pullRef.current.style.transitionDuration  = '.3s';
        this.pullRef.current.style.height = '0px';

        if(this.pullRef.current.clientHeight >= 130) {
            this.refresh();
        }
      }
  }

  render() {
      var refreshClass = "wall-refresh";
      if(this.state.loading) {
        refreshClass += " active";
      } else {
        refreshClass += " inactive after-inactive";
      }

      var containerClass = "row no-gutters wall-container";
      if(this.props.hasTelex) {
        containerClass += " telex";
      }

      if(this.state.activeMenu === false) {
        containerClass += " no-menu";
      }

      if(this.state.editMode === true) {
        containerClass += " edit";
      }




      return (
        <div className="wall-wrapper">
              <div className={refreshClass}>
                  <img className="logo" alt="" src="/app/images/logo512x512.png"></img>
              </div>
              {this.editOptions()}
            {/*<div className="row wall-container">
              <div className="featured col-12">
                <div className="row no-gutters">
                    <div className="first col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-ct-2">
                        <WallItem key={0} data={this.state.items[0]} index={0} onClick={this.onItemClicked.bind(this)} />
                    </div>
                    <div className="second col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2 col-ct">
                         <WallItem key={1} data={this.state.items[1]}  index={1} onClick={this.onItemClicked.bind(this)} />
                    </div>
                    <div className="third col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2 col-ct">
                         <WallItem key={2} data={this.state.items[2]} index={2} onClick={this.onItemClicked.bind(this)} />
                    </div>
                     <div className="col-12 col-md-12 col-lg-12 col-xl-4 col-ct">
                         <div className="row full no-gutters">

                            <div className="col-xs-12 col-md-3 col-lg-3 col-xl-6">
                              <WallItem key={3} data={this.state.items[3]} index={3} onClick={this.onItemClicked.bind(this)} />
                            </div>
                            <div className="col-xs-12 col-md-3 col-lg-3 col-xl-6">
                              <WallItem key={4} data={this.state.items[4]} index={4} onClick={this.onItemClicked.bind(this)} />
                            </div>
                            <div className="col-xs-12 col-md-3 col-lg-3 col-xl-6">
                              <WallItem key={5} data={this.state.items[5]} index={5} onClick={this.onItemClicked.bind(this)} />
                            </div>
                            <div className="col-xs-12 col-md-3 col-lg-3 col-xl-6">
                              <WallItem key={6} data={this.state.items[6]} index={6} onClick={this.onItemClicked.bind(this)} />
                            </div>
                         </div>
                    </div>
                </div>

              </div>
              <div className="items col-12">
                  <div className="row no-gutters">
                      
                            {
                            this.props.feedItems.map((item, index) => {
                              if(index > 6) {
                                return (
                                  <div className="col-12 col-md-3 col-lg-3 col-xl-2">
                                    <WallItem key={index} data={item} index={index} onClick={this.onItemClicked.bind(this)} />
                                  </div>
                                );
                              }
                            })
                        }
                      
                  </div>
              </div>
            </div>
            <div className={containerClass} ref={this.wallContainerRef} onScroll={this.onWindowScroll.bind(this)} onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd} onTouchMove={this.handleTouchMove}>
          */}
            <div className={containerClass} ref={this.wallContainerRef} onScroll={this.onWindowScroll.bind(this)}>
              <div className="pull-to-refresh" ref={this.pullRef}></div>
              <div className="wall-page-grid">

                            {
                              this.state.items.map((item, index) => {
                                //if(index < 30) {
                                  var className = "item";
                                  if(index === 0) {
                                    className = "featured";
                                  } else if(index === 1) {
                                    className = "s-featured";
                                  }
                                  if(this.state.editMode === true) {
                                        className += " edit";
                                  }

                                  return (
                                    <div key={index} className={className}>
                                        <WallItem data={item} index={index} dataLoad={this.props.dataLoad} onClick={this.onItemClicked.bind(this)} onShareClicked={this.shareClicked} repostCallback={this.repostCallback} doShare={this.doShare} closeItemOverlay={this.onCloseItemOverlay} edit={this.state.editMode} onEditItemChange={this.onEditItemSelectionChange} editSelected={this.state.editManager.findItem(item)} activeShare={this.state.activeShare === item.id} activeOptions={this.state.activeOptions === item.id} onShareActivate={this.shareActivate} closeShare={this.closeShare} onOptionsActivate={this.optionsActivate} closeOptions={this.closeOptions} toggleEdit={this.toggleEditWithItem} onCampaignClick={this.onClickShowCampaign} refreshWall={this.refresh} />
                                    </div>
                                  );
                                //}
                                //return "";
                              })
                          }
                    
                        <WallLoader bindScroll={this.bindScroll.bind(this)} nextPage={this.nextPage.bind(this)} />
                      
              </div>
            </div>
          {this.renderItemOverlay()}
          {this.getOverlay()}
          {this.getLoginOverlay()}
        </div>

      );
  }
}

export default WallContainer;