import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import App from './App';


/*if ('serviceWorker' in navigator) {
	window.addEventListener('load', function() {
	  console.log('Registering service worker');

	  navigator.serviceWorker.register('/app/service-worker.js').then(function(registration) {
	    console.log(registration);
	    console.log('Worker registration successful', registration.scope);
	  }, function(err) {
	    console.log('Worker registration failed', err);
	  }).catch(function(err) {
	    console.log(err);
	  });
	  console.log("Hmmm...");
	});
	} else {
	console.log('Service Worker is not supported by browser.');
}*/



ReactDOM.render(<App />, document.getElementById('root'));


function displayUpdateNotification(registration, worker) {
	console.log("Posting update");
	window.postMessage("update-available");
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//serviceWorker.register({onUpdate: displayUpdateNotification});



/*window.addEventListener('beforeinstallprompt', (e) => {
	e.preventDefault();
  	console.log("Install prompt !!");
  	e.prompt();
});*/




