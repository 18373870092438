import React, { Component } from 'react';
import './wall-item.css';
import ShareOverlay from './share-overlay';
import WallItemEdit from './wall-item-edit';
import ComponentOverlay from '../common/component-overlay';

import WallItemChild from './wall-item-child';

class WallItem extends Component {

  constructor(props) {
    super(props);

      this.state = {
          item: props.data,
          editMode: props.edit,
          showTrueDate: false,
          activeChild: 0,
          autoSlide: props.data.isSlide,
      }

      this.shareOverlayRef = React.createRef();
      this.scrollX = 0;
      this.scrollWidth = 0;
      this.scrollRef = React.createRef();
      this.autoSlideInterval = null;
      this.autoSlideDelayTimeout = null;


      this.didScroll = this.didScroll.bind(this);

  }


  componentDidUpdate = (prevProps, prevState) => {
      if(prevProps.data.id !== this.props.data.id || prevProps.edit !== this.props.edit || prevProps.data.children.length !== this.props.data.children.length || prevProps.data.isSlide !== this.props.data.isSlide || prevProps.data.displayMode !== this.props.data.displayMode) {
        console.log("new item");
        this.setState({activeChild: 0, autoSlide: this.props.data.isSlide}, () => {

            /** HACK - If scrollWidth is not accessed, it's impossible to auto scroll after the update **/
            this.scrollWidth = this.scrollRef.current.scrollWidth;
            //this.scrollX = this.scrollRef.current.scrollLeft;
            /** **/

            this.scrollRef.current.scroll({left: 0, behavior: 'instant'});
            this.activateAutoSlide();
        });

      }
  }


  componentDidMount = () => {
      this.scrollWidth = this.scrollRef.current.scrollWidth;
      this.activateAutoSlide();

  }

  componentWillUnmount = () => {
    clearTimeout(this.autoSlideDelayTimeout);
    clearInterval(this.autoSlideInterval);
  }

  delayActivateAutoSlide = (event) => {
      if(event !== undefined)
        event.stopPropagation();
      clearTimeout(this.autoSlideDelayTimeout);
      this.autoSlideDelayTimeout = setTimeout(this.activateAutoSlide, 5000);
  }


  activateAutoSlide = () => {
    if((this.state.autoSlide || this.hasPinnedAutoSlide()) && !this.props.edit) {
      clearInterval(this.autoSlideInterval);
      this.autoSlideInterval = setInterval(this.scrollNext.bind(this, true), 5000);
    }
  }


  cancelAutoSlide = (event) => {
    if(event !== undefined) {
      event.stopPropagation();
    }

      clearTimeout(this.autoSlideDelayTimeout);
      clearInterval(this.autoSlideInterval);
  }


  hasPinnedAutoSlide = () => {
    var count = 0;
    if(this.props.data.isFeature > 0)
        count = 1;

    count += this.props.data.children.filter((child) => {
          return child.isFeature > 0;
    }).length;


    return false;

    return (count > 0 && this.props.data.children.length > 0); 
  }


  clicked = event => {
      this.props.onClick(this.props.data);
  }

  currentItem =  () => {
      return this.getItem(this.state.activeChild);
  }


  getItem = (index) => {
      if(index === 0) {
        return this.props.data;
      } else {
        return this.props.data.children[index-1];
      }   
  }


  shareClicked = (event) => {
      event.preventDefault();
      event.stopPropagation();
      console.log('share clicked');
      this.props.onShareActivate(this.props.data.id);

      this.cancelAutoSlide();
      //this.shareOverlayRef.current.toggle();
      
  }

  optionsClicked = (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.props.onOptionsActivate(this.props.data.id);
  }


  closeShare = () => {
    this.props.closeShare();
  }

  closeOptions = () =>  {
    this.props.closeOptions();
  }

  networkSelected = (feed) => {

    console.log("network selected");

    this.props.onShareClicked(this.getItem(this.state.activeChild), feed, this.renderShareFooter(feed));
    //this.shareOverlayRef.toggle();
  }


  doShare = (feed) => {

    let item = this.getItem(this.state.activeChild);
    console.log("item do share !! ");
    let iframeUrl = process.env.REACT_APP_BASE_URL+'repost?feedItemId='+item.id+'&feedTypeId='+feed.feedTypeId+'&activityTypeId='+feed.actionType+'&bearer='+this.props.dataLoad.apiBearer+'&lng='+this.props.dataLoad.languageManager.currentLanguage;
    this.props.doShare(iframeUrl, item, feed);
  }


  renderOnlyImageContent = () => {
     if(this.props.data.onlyImage === true) {
          const regex = /(<([^>]+)>)/ig;

          var title = null;
          var date = null;

          if(this.props.data.displayMode > 1) {
            var titleText = unescape(this.props.data.title).replace(regex, '');
            if(titleText.length > 80) {
              titleText = titleText.slice(0, 80)+"...";
            }
            title = (<div className="title">{titleText}</div>);
            date = this.renderDate();
          }
          return (<div className="image-content">{title}{date}</div>);
      }

      return "";
  }

  renderImage = () => {
       if(this.props.data.imageURL.length > 0) {
          var pin = "";
          if(this.props.data.isFeature && this.props.data.onlyImage !== true) {
              pin = <div className="pin"><i className="fa fa-thumb-tack"></i></div>
          }

          let imageStyle = { backgroundImage: `url(${process.env.REACT_APP_BASE_URL+this.props.data.imageURL})`, backgroundPosition: this.props.data.imagePosition }

          return (<div className="image" style={imageStyle}>{this.renderOnlyImageContent()}{pin}</div>);
      }
      return "";
  }

  toggleEdit = (event) => {
    this.props.toggleEdit(this.currentItem());
  }

  toggleAdmin = () => {
    window.open("/admin/feeditem/"+this.currentItem().id);
  }


  renderItemOverlayFooter = () => {
      return (<div className="share-footer">{this.renderDate()}<div className="share" onClick={this.shareClicked}><i className="fa fa-share-alt" /></div></div>);
  }
  

  connectAccount = (feed) => {
      window.open(process.env.REACT_APP_BASE_URL+feed.shareUrl+"&bearer="+this.props.dataLoad.apiBearer);
  }


  renderShareFooter = (feed) => {

      var validateButton = "";
      let feedClass = feed.fontAwesome;

      let linked = this.props.dataLoad.isFeedLinked(feed);

        console.log(linked+"?");

      if(linked === false) {
        
        validateButton = <a href={process.env.REACT_APP_BASE_URL+feed.shareUrl+"&bearer="+this.props.dataLoad.apiBearer} target="_blank" rel="opener" className="btn btn-success">{this.props.dataLoad.languageManager.get(47)} <i className={feedClass} /></a>
        window.current = {feedTypeReset: this.reloadFeedAndPresentShare.bind(this, feed) };
      } else  {

        var shareText = this.props.dataLoad.languageManager.get(46);
        if(feed.actionType == 2) {
          shareText = this.props.dataLoad.languageManager.get(45);
        }
        if(feed.actionType == 4) {
          shareText = this.props.dataLoad.languageManager.get(292);
        }


        validateButton = <button className="btn btn-success" onClick={this.doShare.bind(this, feed)}>{shareText} <i className={feedClass} /></button>
        window.wall = { currentModal: { repostCallback: this.props.repostCallback }};

      }


    

      return (
          <div className="network-footer"><button className="btn btn-danger" onClick={this.props.closeItemOverlay}>{this.props.dataLoad.languageManager.get(93)}</button>{validateButton}</div>
        );
  }


  reloadFeedAndPresentShare  = (feed) => {
      console.log("Callback !");
      let promises = [this.props.dataLoad.getCurrentUserPromise(), this.props.dataLoad.getReposFeedType()];

      Promise.all(promises).then(() => {
          this.networkSelected(feed);
      }).catch((error) => {
        console.log(error);
      });
  }



  shareOverlayContent = () => {
      let selectedItem = this.currentItem();

      let overlayContent = this.props.dataLoad.reposFeedTypes.map((feed, index) => {

          if(selectedItem.parentFeed.parentFeedType.id !== 4 && (feed.id === 400 || feed.id === 404)) {
            return null;
          }

          let imageClass = feed.fontAwesome;

          let backgroundStyle = {backgroundColor: feed.color};

         return (<div className="network" alt={feed.name} key={index}><div className="network-content"><div className="wrapper" style={backgroundStyle} onClick={this.networkSelected.bind(this, feed)}><i className={imageClass}></i></div></div></div>)
    });

      return overlayContent;
  }



  editContent = () => {
      if (this.props.edit === true) {
          return (<WallItemEdit item={this.props.data} onEditItemChange={this.props.onEditItemChange} selected={this.props.editSelected} hidden={this.props.data.isHidden} combined={this.props.data.isCombined} />)
      } else {
        return "";
      }
  }



  optionsOverlayContent =  () => {
      var editButton = "";
      var adminButton = "";
      var deleteButton ="";
      if(!this.props.dataLoad.userData.isAnonymous && (this.props.dataLoad.userData.rights.post_edit === true || this.props.dataLoad.hasAdminAccess() === true)) {
          editButton =  <div className="option"><button className="btn btn-secondary edit" onClick={this.toggleEdit} >{this.props.dataLoad.languageManager.get(105)}</button></div>;
          if(this.props.dataLoad.hasAdminAccess() === true) {
            adminButton = <div className="option"><button className="btn btn-secondary admin" onClick={this.toggleAdmin}>{this.props.dataLoad.languageManager.get(58)}</button></div>;
          }
      }

      var originalButton = null;
      if(this.currentItem() && this.currentItem().url.length > 0) {
          originalButton =  <div className="option"><button className="btn btn-secondary show-original" onClick={this.openOriginalUrl}>{this.props.dataLoad.languageManager.get(101)}</button></div>;
      }
      if(parseInt(this.props.data.authorId) === this.props.dataLoad.userData.id) {
        deleteButton = <div className="option"><button className="btn btn-danger" onClick={this.deletePost}>{this.props.dataLoad.languageManager.get(455)}</button></div>
      }

      return (
        <div className="options">
            {originalButton}
            {editButton}
            {adminButton}
            {deleteButton}
        </div>
      );  
  }

deletePost = () => {
  if(confirm(this.props.dataLoad.languageManager.get(486))) {
      this.closeShare();
      this.props.dataLoad.deletePost(this.props.data).then(() => {
          this.props.refreshWall();
      });   
  }
}


 openOriginalUrl = () => {
    window.open(this.currentItem().url);
 }

 scrollNext = (auto) => {
      let slice = this.scrollWidth / (this.props.data.children.length + 1);
      let currentPage = Math.round(this.scrollX / slice);
      this.props.closeShare(this.props.data.id);
      this.props.closeOptions(this.props.data.id);
      let nextPage = currentPage+1;
      this.scrollToPage(nextPage, auto);
 }


 scrollPrevious = () => {
      let slice = this.scrollWidth / (this.props.data.children.length + 1);
      let currentPage = Math.round(this.scrollX / slice);
      this.props.closeShare(this.props.data.id);
      this.props.closeOptions(this.props.data.id);
      let previousPage = currentPage-1;
      this.scrollToPage(previousPage);
 }

 scrollToPage = (page, auto=false) => {
    let slice = this.scrollWidth / (this.props.data.children.length + 1);
    if(page === this.props.data.children.length + 1) {
       page = 0;
    }
    let scroll = page * slice;
    this.scrollRef.current.scroll({left: scroll, behavior: 'smooth'});
    this.setState({activeChild: page});
    if(auto === false) {
        this.cancelAutoSlide();
    }
 }


 renderItem = (item, index) => {

/*      const regex = /(<([^>]+)>)/ig;

      //let titleHtml = { __html: unescape(this.props.data.title)};
      var titleText = unescape(item.title).replace(regex, '');
      let titleHtml = { __html: unescape(titleText)};
      let bodyText = unescape(item.body).replace(regex, '');


      let networkClass = "fa "+item.parentFeed.parentFeedType.fontAwesome;
      var itemClasses = "wall-item ";
      if(item.onlyImage === true) {
        itemClasses += " only-image";
      }
      if(this.props.edit == true) {
        itemClasses += " edit";
      }
      //var colClasses = "col-xs-12 col-sm-6 col-md-3";


      var image="";
      if(item.imageURL.length > 0) {
          let imageStyle = { backgroundImage: `url(https://stage.go4.social${item.imageURL})` }
          image = <div className="image" style={imageStyle}></div>
      }


      var likeCount = "";
      if(item.likeCounter > 0) {
        likeCount = <span className="count"><i className="fa fa-heart" />{TextFormatter.formatNumber(item.likeCounter)}</span>
      }

      var viewCount = "";
      if(item.viewCounter > 0) {
        viewCount = <span className="count"><i className="fa fa-eye" />{TextFormatter.formatNumber(item.viewCounter)}</span>
      }

      var shareCount = "";
      if(item.shareCounter > 0) {
        shareCount = <span className="count"><i className="fa fa-share-alt" />{TextFormatter.formatNumber(item.shareCounter)}</span>
      }


      var controls = null;
      if(this.props.data.children.length > 0) {
        controls = this.renderControls(index+1, this.props.data.children.length + 1);
      }

  return (<div className="child" key={item.id}>
          <ShareOverlay active={this.props.activeShare} ref={this.shareOverlayRef} dataLoad={this.props.dataLoad} onClose={this.closeShare}>{ this.shareOverlayContent() }</ShareOverlay>
          <ComponentOverlay active={this.props.activeOptions} exit={this.props.closeOptions}>{ this.optionsOverlayContent() }</ComponentOverlay>
            {this.editContent()}
            <div className="content" onClick={this.clicked}>
              {this.renderImage()}
              {this.renderCampaign()}
              <div className="body">
                  <div className="header">{likeCount}{viewCount}{shareCount}</div>
                  <div className="body-content">
                    <div className="social-icon"><i className={networkClass} /></div>
                    <h1 dangerouslySetInnerHTML={titleHtml}></h1>
                    <p>{bodyText}</p>
                  </div>
                  <div className="footer"><div className="options"><i onClick={this.optionsClicked} className="fa fa-plus-square" /></div>{this.renderDate()}<div className="share" ><i onClick={this.shareClicked} className="fa fa-share-alt" /></div></div>
              </div>

              <div className="index">{this.props.index}</div>
            </div>
            {controls}
            </div>);*/
            let forceFeature = this.hasPinnedAutoSlide();

          return <WallItemChild key={item.id} item={item} parent={this.props.data} index={index} edit={this.props.edit} childrenCount={this.props.data.children.length} activeShare={this.props.activeShare} onShareClicked={this.props.onShareClicked} dataLoad={this.props.dataLoad} onNext={this.scrollNext} onPrevious={this.scrollPrevious} onClick={this.props.onClick} onOptionsActivate={this.props.onOptionsActivate} onShareActivate={this.props.onShareActivate} onCampaignClick={this.props.onCampaignClick} forceFeature={forceFeature}/>  
  
 }

 isCurrentActiveItem = (index) => {
    let slice = this.scrollWidth / (this.props.data.children.length + 1);
    let currentPage = Math.round(this.scrollX / slice);
    if(currentPage === index) {
        return true;
    }
    return false;
     
 }


 renderChildren = () => {
    if(!this.props.edit) {
        return this.props.data.children.map((child, index) => {
            return this.renderItem(child, index+1);
        });
    }
 }

 renderControls = (index, count) => {

    var previousPageControl = null;
    var nextPageControl = null;

    if(index >= 0 && index < count-1) {
        nextPageControl = <div className="next" onClick={this.scrollNext}><i className="fa fa-chevron-right" /></div>;
    }
    if(index > 0) {
        previousPageControl = <div className="previous" onClick={this.scrollPrevious}><i className="fa fa-chevron-left" /></div>;
    }

    return (<div className="page-controls">
          {previousPageControl}
          {nextPageControl}
    </div>);
 }


 didScroll = (event) => {
    event.stopPropagation();
    this.scrollX = event.target.scrollLeft;
    this.scrollWidth = event.target.scrollWidth;
 }


 renderShareOverlay = () => {
    if(this.props.activeShare) {
      return <ShareOverlay active={this.props.activeShare} ref={this.shareOverlayRef} dataLoad={this.props.dataLoad} onClose={this.closeShare}>{ this.shareOverlayContent() }</ShareOverlay>
    }
    return null;
 }


  render() {
      var itemClasses = "wall-item ";
      if(this.props.data.onlyImage === true) {
        itemClasses += " only-image";
      }
      if(this.props.edit === true) {
        itemClasses += " edit";
      }

      return (
        <div className="item-holder" onPointerEnter={this.cancelAutoSlide} onPointerLeave={this.delayActivateAutoSlide}>
          <ComponentOverlay active={this.props.activeOptions} exit={this.props.closeOptions}>{ this.optionsOverlayContent() }</ComponentOverlay>
          {this.renderShareOverlay()}
          {this.editContent()}
          <div className={itemClasses} onScroll={this.didScroll} ref={this.scrollRef}>
  
            {this.renderItem(this.props.data, 0)}
            {this.renderChildren()}
            
          </div>
        </div>
      );
  }

}

export default WallItem;