import React, { Component } from 'react';
import './App.css';
import AppLoader from './components/common/app-loader';
import DataLoad from './logic/data-load';
import WallContainer from './components/wall/wall-container';
import TopMenu from './components/wall/top-menu';
import Telex from './components/common/telex';
import MyProfile from './components/profile/my-profile';
import { BrowserRouter as Router, Route, Switch, Link, useParams, withRouter, useLocation, useHistory } from "react-router-dom";
import UpdatePopup from './components/common/update-popup';
import BadgesPage from './components/profile/pages/badges-page';
import EditProfilePage from './components/profile/pages/edit-profile-page';
import GamificationPage from './components/profile/pages/gamification-page';
import LeaderboardPage from './components/profile/pages/leaderboard-page';
import ChannelsPage from './components/profile/pages/channels-page';
import CampaignsPage from './components/profile/pages/campaigns-page';
import StatsPage from './components/profile/pages/stats-page';
import Navbar from './components/common/navbar';
import ViewportDisplay from './components/common/viewport-display';
import QueryString from 'query-string';
import PageError from './components/common/page-error';
import PWAPrompt from 'react-ios-pwa-prompt';
import ReactGA from 'react-ga';


//const history = useHistory();

const navItems = [
  {
    "titleId": 1,
    "route": "/"
  }
];

class App extends Component {

  constructor(props) {
      super(props);


      this.dataLoad = new DataLoad();
      this.wallContainerRef = React.createRef();

      this.state = {
        appLoaded: false,
        appKey: new Date().getTime() / 1000,
        menuKey: (new Date().getTime() / 1000)+1,             
        activeMenu: true,
        updateAvailable: false,
        error: false,
      }

      this.forceTv = false;
      this.originalLocation = null;
      this.refreshQueue = [];      

                                                                                                                                                                                                             
  }


  componentDidCatch = (error, info) => {

  }

  componentDidMount() {
      window.addEventListener("message", this.updateAvailable);
      this.checkAndTriggerMode();
      console.log("Listening to updates");


      this.dataLoad.start().then(res => {
        console.log('GA Init');
        ReactGA.initialize(this.dataLoad.globalSettings.googleAnalyticsCode, {debug: false});
        ReactGA.pageview(window.location.pathname);
         
          if(window.shareDialog)
            window.shareDialog.init(this.dataLoad.apiBearer, this.dataLoad.languageManager.currentLanguage);
          return true;
         // this.setState({appLoaded: true});
      }).catch((error) => {
          console.log(error);
          this.setState({error: true});
      }).then(() => {
          document.title = process.env.REACT_APP_NAME;
          console.log("App loaded ?");
          this.setState({appLoaded: true});
         // setTimeout(this.checkForUpdate, 10000);

      }); 
  }

  componentWillUnmount() {
    this.unlisten();
  }

  checkAndTriggerMode = () => {
    let mode = QueryString.parse(window.location.search, { ignoreQueryPrefix: true }).mode;
    let channel = QueryString.parse(window.location.search, { ignoreQueryPrefix: true }).channel;
    console.log("Mode :"+mode);
    if(mode === "tv") {
      window.location.href = "/app/tv";
      return;
    }
    console.log("Channel : "+channel);
    if(channel !== undefined) {
      window.location.href = "/app/channel/"+channel;
    }
  }

  updateAvailable = (event) => {
    if(event.data === "update-available") {
      console.log("Got update available");
      this.setState({updateAvailable: true});
    }   
  }

  refreshWall = () => {
      this.wallContainerRef.current.refresh();
  }


  toggleEdit = () => {
      this.wallContainerRef.current.toggleEdit();
  }

  presentLogin = () => {
    this.wallContainerRef.current.presentLogin();
  }

  showCreatePostModal = () => {
    this.wallContainerRef.current.showCreatePostModal();
  }

  onLanguageChange = () => {
      this.refreshApp();
  }


  refreshApp = () => {
	  this.forceUpdate();
  }

  toggleTelexChannel = () => {
      this.dataLoad.filtersManager.forceChannel(this.dataLoad.getMasterTelexChannel().id);
      this.refreshWall();
      this.refreshMenu();
  }

  refreshMenu = () => {
    this.setState({menuKey: new Date().getTime() / 1000});
  }

  onMenuHidden = () => {
    this.setState({activeMenu: false});
  }

  onMenuShown = () => {
    this.setState({activeMenu: true});
  }

  onUpdateAccepted = () => {
    console.log("Update accepted by user, triggering skip waiting for new SW");
      navigator.serviceWorker.getRegistration().then((registration) => {
          if(registration.waiting !== null) {
            registration.waiting.postMessage("skip-waiting");
          }
      });
  }

  onUpdateRefused = () =>  {
      this.setState({updateAvailable: false});
  }


  displayUpdateNotification =  () => {
    if(this.state.updateAvailable) {
      return (<UpdatePopup dataLoad={this.dataLoad} onUpdateAccepted={this.onUpdateAccepted} onUpdateRefused={this.onUpdateRefused} />);
    }
    return null;
  }

  reloadApp = () => {
    console.log("reloading app");
    window.location.reload();
  }


  tvGuestApp = () => {
      return this.guestApp(true);
  }

  guestApp = (tv= false) => {
    if(this.state.error == true) {
      return this.render500(true);
    }
    let { channelSlug } = useParams();
    console.log('slug', channelSlug);
    if(channelSlug !== undefined) {
          let channelName = channelSlug;
          this.dataLoad.forcedChannelName = channelName;
    }

     window.closeLoginPopup = this.reloadApp;
     if(this.state.appLoaded) {
        if(this.dataLoad.globalSettings.allowAnonymous === false && this.dataLoad.userData.isAnonymous === true) {
           return this.login();
        } else {
          var telex = "";
          let hasTelex = this.dataLoad.hasTelexChannel();
          if(hasTelex) {
              telex = <Telex data={this.dataLoad.telexItems} ownerImage={this.dataLoad.getMasterTelexChannel().imageURL} toggleTelexChannel={this.toggleTelexChannel} />
          }

          var appClass = "container-fluid";
          if(tv === true || this.forceTv === true) {
            appClass += " tv";
          }
        
         return (
            <div id="app" className={appClass} key={this.state.appKey}>
              <TopMenu key={this.state.menuKey} refreshWall={this.refreshWall} dataLoad={this.dataLoad} onLanguageChange={this.onLanguageChange} toggleEdit={this.toggleEdit}  active={this.state.activeMenu} isWall={true} presentLogin={this.presentLogin} showCreatePostModal={this.showCreatePostModal} />
              <WallContainer ref={this.wallContainerRef} dataLoad={this.dataLoad} feedItems={this.dataLoad.originalFeedItems} nextPage={this.dataLoad.getNextPage} hasTelex={hasTelex} onMenuHidden={this.onMenuHidden} onMenuShown={this.onMenuShown}/>
              {telex}
              {this.displayUpdateNotification()}
              {this.renderPWAPrompt()}
            </div>);
       }
      } else {
            return (<AppLoader />);
      }
  }


  renderPWAPrompt = () => {
    //if(window.navigator === undefined || window.navigator.standalone === false) {
      return <PWAPrompt copyTitle={this.dataLoad.languageManager.get(322)} copyBody={this.dataLoad.languageManager.get(323)} copyShareButtonLabel={this.dataLoad.languageManager.get(325)} copyAddHomeButtonLabel={this.dataLoad.languageManager.get(326)} copyClosePrompt={this.dataLoad.languageManager.get(93)} />; 
    //}
    return null;
  }

  login = () => {
    let iframeUrl = process.env.REACT_APP_BASE_URL+"/login20?lng="+this.dataLoad.languageManager.currentLanguage+"&bearer="+encodeURI(this.dataLoad.apiBearer);
    return (
      <div className="frame-container">
      <iframe className="login-frame" title="login" src={iframeUrl} width="320" height="480" scrolling="no" />
      </div>
    )
  }

  logout = () => {
    return (
      <iframe title="logout" src={process.env.REACT_APP_BASE_URL+"logout"}></iframe>
    )
  }

  returnToApp = () => {
    this.props.history.push("/app");
  }

  profile = () => {
    if(this.state.error == true) {
      return this.render500();
    }
    if(this.state.appLoaded) {
      return (
        <div id="app" className="container-fluid" key={this.state.appKey}>
          <TopMenu key={this.state.menuKey} refreshWall={this.refreshWall} dataLoad={this.dataLoad} onLanguageChange={this.onLanguageChange} toggleEdit={this.toggleEdit} returnOnLogoClicked={true} isWall={false} active={this.state.activeMenu}   />
          <MyProfile dataLoad={this.dataLoad} />
        </div>
      )
    } else {
      return (<AppLoader />);
    }
    
  }


  badges = () => {
    if(this.state.appLoaded) {
         return (
        <React.Fragment>
        <Navbar title={this.dataLoad.languageManager.get(111)}  />
        <BadgesPage badges={this.dataLoad.userGameLevelData} badgeCount={this.dataLoad.userData.badgeCount} />
        </React.Fragment>
      )   
    } else {
      return (<AppLoader />);
    }

  }

  stats = () => {
    if(this.state.appLoaded) {
         return (
        <React.Fragment>
          <Navbar title="Stats" />
          <StatsPage dataLoad={this.dataLoad} />
        </React.Fragment>
      )   
    } else {
      return (<AppLoader />);
    }    
  }

  editProfile = () => {
    if(this.state.appLoaded) {
         return (
        <React.Fragment>
          <Navbar title={this.dataLoad.languageManager.get(230)} />
          <EditProfilePage dataLoad={this.dataLoad} onReloadUser={this.reloadUser} />
        </React.Fragment>
      )   
    } else {
      return (<AppLoader />);
    }       
  }

  reloadUser = () => {
    this.dataLoad.getCurrentUserPromise().then((user) => {
        this.forceUpdate();
    });
  }


  gamification = () => {
    if(this.state.appLoaded) {
         return (
        <React.Fragment>
          <Navbar title="Gamification" />
          <GamificationPage dataLoad={this.dataLoad} />
        </React.Fragment>
      )   
    } else {
      return (<AppLoader />);
    }          
  }


  leaderboard = () => {
    if(this.state.appLoaded) {
         return (
        <React.Fragment>
          <Navbar title={this.dataLoad.languageManager.get(122)} />
          <LeaderboardPage dataLoad={this.dataLoad} />
        </React.Fragment>
      )   
    } else {
      return (<AppLoader />);
    }          
  }


  channels = () => {
    if(this.state.appLoaded) {
         return (
        <React.Fragment>
          <Navbar title={this.dataLoad.languageManager.get(215)}  />
          <ChannelsPage dataLoad={this.dataLoad} />
        </React.Fragment>
      )   
    } else {
      return (<AppLoader />);
    }          
  }



  campaigns = () => {
    if(this.state.appLoaded) {
         return (
        <React.Fragment>
          <Navbar title={this.dataLoad.languageManager.get(121)}  />
          <CampaignsPage dataLoad={this.dataLoad} />
        </React.Fragment>
      )   
    } else {
      return (<AppLoader />);
    }          
  }


  renderMenu = () => {
    return (<div className="test">Ayouuuuu</div>);
  }

  render404 = () => {
    return <PageError error="404" />;
  }

  render500 = (forceLogoutOnReturn=false) => {
    return <PageError error="500" forceLogoutOnReturn={forceLogoutOnReturn} />;
  }


  applicationRouter = () => {
     return (
    <Router basename='/app'>
      <div>
      <Switch>
        <Route exact path="/" component={this.guestApp} />
        <Route exact path="/wall" component={this.tvGuestApp} />
        <Route exact path="/profile" component={this.profile} />        
        <Route exact path="/profile/badges" component={this.badges} />
        <Route exact path="/profile/stats" component={this.stats} />
        <Route exact path="/profile/edit" component={this.editProfile} />
        <Route exact path="/profile/gamification" component={this.gamification} />
        <Route exact path="/profile/leaderboard" component={this.leaderboard} />
        <Route exact path="/profile/channels" component={this.channels} />
        <Route exact path="/profile/campaigns" component={this.campaigns} />
        <Route exact path="/loginUser" component={this.login} />
        <Route exact path="/channel/:channelSlug?" component={this.guestApp} />
        <Route component={this.render404} />
      </Switch>
      </div>
    </Router>
    );
  }


  render() {
    return this.applicationRouter();
  }


}

export default App;
