import React, { Component } from 'react';
import TextFormatter from '../../logic/text-formatter'
import Commons from '../../logic/commons';
import './wall-item-child.css';
import WallItemEdit from './wall-item-edit';

class WallItemChild extends Component {

  constructor(props) {
    super(props);

      this.state = {
          item: props.data,
          editMode: props.edit,
          showTrueDate: false,
      }


      this.shareOverlayRef = React.createRef();
  }


  clicked = event => {
      this.props.onClick(this.props.item);
  }


  shareClicked = (event) => {
      event.preventDefault();
      event.stopPropagation();
      console.log('share clicked');
      this.props.onShareActivate(this.props.parent.id);
      //this.shareOverlayRef.current.toggle();
      
  }

  optionsClicked = (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.props.onOptionsActivate(this.props.parent.id);
  }

  networkSelected = (feed) => {

    console.log("network selected");

    this.props.onShareClicked(this.props.item, feed, this.renderShareFooter(feed));
    //this.shareOverlayRef.toggle();
  }


  doShare = (feed) => {
    console.log("CHild do share !! ");
      let iframeUrl = process.env.REACT_APP_BASE_URL+'repost?feedItemId='+this.props.item.id+'&feedTypeId='+feed.id+'&activityType='+feed.actionType+'&bearer='+this.props.dataLoad.apiBearer;
      this.props.doShare(iframeUrl);
  }


  renderOnlyImageContent = () => {
     if(this.props.item.displayMode > 2) {
          const regex = /(<([^>]+)>)/ig;

          var title = this.props.item.title;
          /*if(this.props.item.parentFeed.isSearch == true || this.props.item.parentFeed.parentFeedType.id == 1) {
              title = this.props.item.authorName;
          }*/
          var titleText = TextFormatter.decodeHTMLEntities(title, true);
          if(titleText.length > 80) {
            titleText = titleText.slice(0, 80)+"...";
          }
          let titleHTML = {__html: titleText};

          return (<div className="image-content"><div className="title" dangerouslySetInnerHTML={titleHTML}></div>{this.renderDate()}</div>);
      }

      return "";
  }

  renderImage = () => {
       if(this.props.item.imageURL.length > 0) {
          var pin = null;
          if((this.props.item.isFeature || this.props.forceFeature) && this.props.dataLoad.globalSettings.featureIconTransparent == false && this.props.item.displayMode === 2) {
              pin = <div className="pin"><i className="fas fa-thumbtack"></i></div>
          }
          let imageStyle = { backgroundImage: `url(${process.env.REACT_APP_BASE_URL+this.props.item.imageURL})`, backgroundPosition: this.props.item.imagePosition }
          var imageContent = null;
          if(this.props.item.displayMode > 2) {
            imageContent = this.renderOnlyImageContent();
          }
          return (<div className="image" style={imageStyle}>{imageContent}{pin}</div>);
      }
      return "";
  }


  renderSocialIcons = () => {
    if(this.props.item.children.length > 0) {
          
      let socialTypes = this.props.item.children.map((child) => {
            return child.parentFeed.parentFeedType.fontAwesome;
      });
      socialTypes.unshift(this.props.item.parentFeed.parentFeedType.fontAwesome);

      const distinct = (value, index, self) => {
         return self.indexOf(value) === index;
      }
      socialTypes = socialTypes.filter(distinct);

      return socialTypes.map((socialType, index) => {
        return this.renderSocialIcon(socialType, index);
      });

    } else {
        return this.renderSocialIcon(this.props.item.parentFeed.parentFeedType.fontAwesome, "");
    }
  }



  renderSocialIcon = (faClass, index) => {
        let networkClass = "fab "+faClass;
        let iconClass = "social-icon s"+index;
        
        return  <div className={iconClass} key={"item-"+this.props.item.id+"-social-"+index}><i className={networkClass} /></div>
  }

  toggleTrueDate = (event) => {
    event.stopPropagation();
    this.setState({showTrueDate: !this.state.showTrueDate});
  }

  toggleEdit = (event) => {
    this.props.toggleEdit(this.props.item);
  }

  toggleAdmin = () => {
    window.open("/admin/feeditem/"+this.props.item.id);
  }


  renderItemOverlayFooter = () => {
      return (<div className="share-footer">{this.renderDate()}<div className="share" onClick={this.shareClicked}><i className="fa fa-share-alt" /></div></div>);
  }


  renderShareFooter = (feed) => {

      var validateButton = "";
      let feedClass = feed.fontAwesome;

      let linked = this.props.dataLoad.isFeedLinked(feed);

      console.log("child linked ? "+linked);

      if(linked === false) {
        
        validateButton = <button className="btn btn-success">{this.props.dataLoad.languageManager.get(47)} <i className={feedClass} /></button>;
        window.current = {feedTypeReset: this.reloadFeedAndPresentShare.bind(this, feed) };
      } else {
        var title = this.props.dataLoad.languageManager.get(46);
        if(feed.actionType === 2) {
          title = this.props.dataLoad.languageManager.get(45);
        }
        if(feed.actionType === 4) {
          title = this.props.dataLoad.languageManager.get(292);
        }
        validateButton = <button className="btn btn-success" onClick={this.doShare.bind(this, feed)}>{title} <i className={feedClass} /></button>

        window.wall = { currentModal: { repostCallback: this.props.repostCallback }};
      }




      return (
          <div className="network-footer"><button className="btn btn-danger" onClick={this.props.closeItemOverlay}>{this.props.dataLoad.languageManager.get(93)}</button>{validateButton}</div>
        );
  }

  reloadFeedAndPresentShare  = (feed) => {
      console.log("Callback !");
      let promises = [this.props.dataLoad.getCurrentUserPromise(), this.props.dataLoad.getReposFeedType()];

      Promise.all(promises).then(() => {
          this.networkSelected(feed);
      }).catch((error) => {
        console.log(error);
      });
  }

 /* networkSelected = (feed) => {

    console.log("network selected");

    this.props.onShareClicked(this.getItem(this.state.activeChild), feed, this.renderShareFooter(feed));
    //this.shareOverlayRef.toggle();
  }*/



  shareOverlayContent = () => {
      let overlayContent = this.props.dataLoad.reposFeedTypes.map((feed, index) => {

          if(this.props.item.parentFeed.parentFeedType.id === 4 && (feed.id === 400 || feed.id === 404)) {
            console.log("exluded"+feed.id);
            return null;
          }
          let imageClass = feed.fontAwesome;

          let backgroundStyle = {backgroundColor: feed.color};

         return (<div className="network" alt={feed.name} key={index}><div className="network-content"><div className="wrapper" style={backgroundStyle} onClick={this.networkSelected.bind(this, feed)}><i className={imageClass}></i></div></div></div>)
    });

      return overlayContent;
  }



  editContent = () => {
      if (this.props.edit === true) {
          return (<WallItemEdit item={this.props.item} onEditItemChange={this.props.onEditItemChange} selected={this.props.editSelected} hidden={this.props.data.isHidden} combined={this.props.data.isCombined} />)
      }
      return null;
  }


  onClickCampaign = (event) => {
    event.stopPropagation();

    this.props.onCampaignClick(this.props.item);
  }

  renderCampaign = () => {
    if(this.props.item.campaign.id > 0 /*&& this.props.dataLoad.globalSettings.gamification === true*/) {
        return <div className="campaign-tag" onClick={this.onClickCampaign}><div className="campaign-bonus">{this.props.item.campaign.bonusName}</div></div>
    }
    return null;
  }



  renderDate = () => {


    let text = TextFormatter.formatDate(this.props.item, this.state.showTrueDate, this.props.dataLoad.languageManager, this.props.forceFeature);

  

     return ( <div className="time" onClick={this.toggleTrueDate}>
          {text}
      </div>);
  }


  hasOptions = () => {
      return (!this.props.dataLoad.userData.isAnonymous && (this.props.dataLoad.userData.rights.post_edit === true || this.props.dataLoad.hasAdminAccess())) || this.props.item.url.length > 0 || parseInt(this.props.item.authorId) === this.props.dataLoad.userData.id;
  }



deletePost = () => {
  this.props.dataLoad.deletePost(this.props.item);
}


 openOriginalUrl = () => {
    window.open(this.props.item.url);
 }


renderCount = (value, faSuffix) => {
  if(value > 0) {
      return <span className="count"><i className={"fa "+faSuffix} />{TextFormatter.formatNumber(value)}</span>;
  }
  return null;
}

renderTitleHtml = (title) => {
    return (<h1 dangerouslySetInnerHTML={{ __html: TextFormatter.decodeHTMLEntities(title, true)}}></h1>);
}

renderBodyHtml = (body) => {
  return <p dangerouslySetInnerHTML={{ __html: TextFormatter.decodeHTMLEntities(body, true)}}></p>
}


renderAuthor = (item) => {
  if(item.parentFeed.isSearch == true || item.parentFeed.parentFeedType.id == 1) {
      return this.renderAuthorName(item.authorName);
  }
  return null;
}

renderAuthorName = (authorName) => {
    return (<div className="item-author">{ authorName }</div>);
}

getItemClass = (item) => {
    var itemClass = "child";
    if(item.displayMode > 1) {
      itemClass += " only-image";
    }
    if(this.props.edit == true) {
      itemClass += " edit";
    }

    return itemClass;
}
 renderItem = (item, index) => {
      var itemsToCount = [item];
      if(item.children.length > 0) {
         itemsToCount = itemsToCount.concat(item.children);
      }

      let counts = Commons.getSocialCounts(itemsToCount);

      var controls = null;
      if(this.props.childrenCount > 0 && !this.props.edits ) {
        controls = this.renderControls(index, this.props.childrenCount + 1);
      }


      let likeSuffix = "fa-heart";
      if(item.isYouTube == true) {
        likeSuffix = "fa-thumbs-up";
      }

  return (<div className={this.getItemClass(item)}>
            <div className="content" onClick={this.clicked}>
              {this.renderImage()}
              {this.renderCampaign()}
              <div className="body">
                  <div className="header">{this.renderCount(counts.like, likeSuffix)}{this.renderCount(counts.view, "fa-eye")}{this.renderCount(counts.share, "fa-share-alt")}</div>
                  <div className="body-content">
                    {this.renderSocialIcons()}
                    {this.renderAuthor(item)}
                    {this.renderTitleHtml(item.title)}
                    {this.renderBodyHtml(item.body)}
                  </div>
                  {this.renderItemFooter()}
              </div>
            </div>
            {controls}
            </div>);
 }

 renderItemFooter = () => {
	return (
	  <div className="footer">{this.renderOptionsButton()}{this.renderDate()}{this.renderShareButton()}</div>
	)
 }
 
 renderOptionsButton = () => {
  var icon = null;
  if(this.hasOptions()) {
    icon = <i onClick={this.optionsClicked} className="fa fa-plus-square" />;
  }
	return (
		<div className="options">{icon}</div>
	);
}
 
 renderShareButton = () => {
	 if(this.props.item.url.length == 0 || this.props.item.allowShare === false) {
		 return  <div className="share" ></div>
	 }
	 return (
	 <div className="share" ><i onClick={this.shareClicked} className="fa fa-share-alt" /></div>
	 );
}

 renderControls = (index, count) => {

    var previousPageControl = null;
    var nextPageControl = null;

    if(index >= 0 && index < count-1) {
        nextPageControl = <div className="next" onClick={this.props.onNext}><i className="fa fa-chevron-right" /></div>;
    }
    if(index > 0) {
        previousPageControl = <div className="previous" onClick={this.props.onPrevious}><i className="fa fa-chevron-left" /></div>;
    }

    return (<div className="page-controls">
          {previousPageControl}
          {nextPageControl}
    </div>);
 }



  render() {
      return this.renderItem(this.props.item, this.props.index);
  }

}

export default WallItemChild;