import React, { Component } from 'react';
import "./item-overlay.css";
import TextFormatter from '../../logic/text-formatter';
import ShareOverlay from './share-overlay';
import ComponentOverlay from '../common/component-overlay';
import moment from 'moment';

class ItemOverlay extends Component {


  constructor(props) {
    super(props);
    this.state = {
      showTrueDate: false,
      shareFeed: null,
      activeShare: false,
      activeOptions: false,
      activities: [],
    };


    this.shareOverlayRef = React.createRef();
  }


  componentDidMount = () => {

  }

  componentDidUpdate = (prevProps) => {
      if(this.props.item !== null && prevProps.item !== this.props.item) {
        this.props.dataLoad.trackItemClick(this.props.item);
      }
      if(this.props.footer === null && this.state.shareFeed == null && this.props.item !== null && prevProps.item == null) {
        console.log("loading item activities");
          this.props.dataLoad.getItemActivity(this.props.item).then((activities => {

                this.setState({activities: activities.filter((activity, index) => { return index < 5 })});
          }));
      }
  }


  contentClick = event => {
      event.stopPropagation();
  }


    onDrag = (event) => {
      event.stopPropagation();
    }

    renderFooter = () => {
      if(this.props.footer !== null) {
        return this.props.footer;
      } else if(this.state.shareFeed !== null) {
        return this.renderShareFooter(this.state.shareFeed);
      } else {
        return this.renderDefaultFooter();
      }
    }


  doShare = (feed) => {
    console.log("DO Share !!!");
      this.setState({shareFeed: null});
      let iframeUrl = process.env.REACT_APP_BASE_URL+'repost?feedItemId='+this.props.item.id+'&feedTypeId='+feed.feedTypeId+'&activityTypeId='+feed.actionType+'&bearer='+this.props.dataLoad.apiBearer;
      this.props.doShare(iframeUrl, this.props.item, feed);
  }


  cancelShare = () => {
    this.props.onCancelShare();
    this.setState({shareFeed: null});
  }

  connectAccount = (feed) => {
      window.open(process.env.REACT_APP_BASE_URL+feed.shareUrl+"&bearer="+this.props.dataLoad.apiBearer);
  }


  renderShareFooter = (feed) => {
      var validateButton = "";
      let feedClass = feed.fontAwesome;
      let linked = this.props.dataLoad.isFeedLinked(feed);


      if(linked === false) {
        validateButton = <button className="btn btn-success" onClick={this.connectAccount.bind(this, feed)}>{this.props.dataLoad.languageManager.get(47)} <i className={feedClass} /></button>;
        window.current = {feedTypeReset: this.reloadFeedAndPresentShare.bind(this, feed) };
      } else {

        var shareText = this.props.dataLoad.languageManager.get(46);
        if(feed.actionType == 2) {
          shareText = this.props.dataLoad.languageManager.get(45);
        }
        if(feed.actionType == 4) {
          shareText = this.props.dataLoad.languageManager.get(292);
        }
        validateButton = <button className="btn btn-success" onClick={this.doShare.bind(this, feed)}>{shareText} <i className={feedClass} /></button>
        window.wall = { currentModal: { repostCallback: this.props.repostCallback }};
        
      }

      return (
          <div className="network-footer"><button className="btn btn-danger" onClick={this.cancelShare}>{this.props.dataLoad.languageManager.get(93)}</button>{validateButton}</div>
        );
  }


  reloadFeedAndPresentShare  = (feed) => {
      console.log("Callback !");
      let promises = [this.props.dataLoad.getCurrentUserPromise(), this.props.dataLoad.getReposFeedType()];

      Promise.all(promises).then(() => {
          this.networkSelected(feed);
      }).catch((error) => {
        console.log(error);
      });

  }


  renderDefaultFooter = () => {
      return (
        <React.Fragment>
          <div className="share-footer">{this.renderOptionsButton()}{this.renderDate()}{this.renderShareButton()}</div>
          {this.renderActivityFooter()}
        </React.Fragment>
        );
  }

  hasOptions = () => {
    return (!this.props.dataLoad.userData.isAnonymous && (this.props.dataLoad.userData.rights.post_edit === true || this.props.dataLoad.hasAdminAccess())) || this.props.item.url.length > 0 || parseInt(this.props.item.authorId) === this.props.dataLoad.userData.id;
  }
  
  renderOptionsButton = () => {
    if(!this.hasOptions()) {
      return (<div className="options"></div>);
    }
	return (
		<div className="options"><i onClick={this.optionsClicked} className="fa fa-plus-square" /></div>
	);
}
 
 renderShareButton = () => {
	 if(this.props.item.url.length == 0 || this.props.item.allowShare === false) {
		 return <div className="share" ></div>;
	 }
	 return (
	 <div className="share" ><i onClick={this.shareClicked} className="fa fa-share-alt" /></div>
	 );
} 




  renderActivityFooter = () => {
      return (
        <div className="item-activities">
              { this.state.activities.map((activity, index) => {
                  return this.renderActivity(activity, index);
              }) }
        </div>
      );
  }


  renderActivity = (activity, index) => {
      return (
        <div className="item-activity" key={index}>
          <div className="avatar">
                <img alt="Activity User" src={process.env.REACT_APP_BASE_URL+ activity.user.pictureUrl} />
          </div>
          <div className="share-message">
              <strong>{activity.user.name}</strong>&nbsp;{this.getActivityMessage(activity) + "  " + this.props.dataLoad.languageManager.get(205) + " " + activity.feedType.name}  {" " + this.renderActivityDate(activity) }
          </div>
        </div>
      )
  }

  renderActivityDate = (activity) => {
      return this.props.dataLoad.languageManager.get(1) + " " + moment(activity.dateCreated).format('L');
  }

  getActivityMessage = (activity) => {
      if(activity.actionType == 2) {
        return this.props.dataLoad.languageManager.get(88);
      } else {
        return this.props.dataLoad.languageManager.get(89);
      }
  }

  toggleTrueDate = () => {
      this.setState({showTrueDate: !this.state.showTrueDate});
  }

  renderDate = () => {
    let text = TextFormatter.formatDate(this.props.item, this.state.showTrueDate, this.props.dataLoad.languageManager);
     return (<div className="time" onClick={this.toggleTrueDate}>
          {text}
      </div>);
  }

  renderShareOverlay = () => {
      return (<ShareOverlay ref={this.shareOverlayRef} active={this.state.activeShare} dataLoad={this.props.dataLoad} onClose={this.closeShare}>{ this.shareOverlayContent() }</ShareOverlay>);

  }


  shareClicked = () => {
    this.setState({activeShare: true});
  }

  optionsClicked = () => {
    this.setState({activeOptions: true});
  }

  closeShare = () => {
    this.setState({activeShare:false});
  }


  networkSelected = (feed) => {

    console.log("network selected");

    console.log("why ?");

    this.setState({shareFeed: feed});

    this.props.onShareClicked(this.props.item, feed, this.renderShareFooter(feed));

    //this.shareOverlayRef.toggle();
  }

  shareOverlayContent = () => {
      let overlayContent = this.props.dataLoad.reposFeedTypes.map((feed, index) => {


          if(this.props.item.parentFeed.parentFeedType.id !== 4 && (feed.id === 400 || feed.id === 404)) {
            return null;
          }


          let imageClass = feed.fontAwesome+" fa-fw";

          let backgroundStyle = {backgroundColor: feed.color};

          return (<div className="network" alt={feed.name} key={index}><div className="network-content"><div className="wrapper" style={backgroundStyle} onClick={this.networkSelected.bind(this, feed)}><i className={imageClass}></i></div></div></div>)
      });

      return overlayContent;
  }

  onCampaignClick = (event) => {
    console.log("campaign click");
    event.preventDefault();
    this.onClose();
    this.props.onCampaignClick(this.props.item);
  }




  renderCampaign = () => {
    if(this.props.item.campaign.id > 0 && this.props.dataLoad.globalSettings.gamification === true) {
        return <div className="campaign-tag" onClick={this.onCampaignClick}><div className="campaign-bonus">{this.props.item.campaign.bonusName}</div></div>
    }

    return "";
  }

  openOriginalUrl = () => {
    window.open(this.props.item.url);
  }


  onClose = () => {
    this.setState({shareFeed: null, activeShare:false, activeOptions:false});
    this.props.onClose();
  }

  closeOptions = () => {
    this.setState({activeOptions: false});
  }

  renderComments = () => {
      if(this.props.item.commentCounter > 0) {
          return (<div className="comments" onClick={this.openOriginalUrl}>{this.props.dataLoad.languageManager.get(95)} ({this.props.item.commentCounter})</div>);
      }
  }

  toggleAdmin = () => {
        window.open("/admin/feeditem/"+this.props.item.id);
  }


  toggleEdit = (event) => {
    this.props.toggleEdit(this.props.item);
  }

  renderOptionsOverlay = () => {
      var editButton = "";
      var adminButton = "";
      var deleteButton ="";
      if(!this.props.dataLoad.userData.isAnonymous && (this.props.dataLoad.userData.rights.post_edit === true || this.props.dataLoad.hasAdminAccess() === true)) {
          editButton =  <div className="option"><button className="btn btn-secondary edit" onClick={this.toggleEdit} >{this.props.dataLoad.languageManager.get(105)}</button></div>;
          if(this.props.dataLoad.hasAdminAccess() === true) {
            adminButton = <div className="option"><button className="btn btn-secondary admin" onClick={this.toggleAdmin}>{this.props.dataLoad.languageManager.get(58)}</button></div>;
          }
      }

      var originalButton = null;
      if(this.props.item.url.length > 0) {
          originalButton =  <div className="option"><button className="btn btn-secondary show-original" onClick={this.openOriginalUrl}>{this.props.dataLoad.languageManager.get(101)}</button></div>;
      }
      if(parseInt(this.props.item.authorId) === this.props.dataLoad.userData.id) {
        deleteButton = <div className="option"><button className="btn btn-danger" onClick={this.deletePost}>{this.props.dataLoad.languageManager.get(455)}</button></div>
      }

      return (
        <div className="options">
            {originalButton}
            {editButton}
            {adminButton}
            {deleteButton}
        </div>
      );  
  }

  renderEmbedVideo = () => {
      let i = this.props.item.url.lastIndexOf('v=');
      let vidId = this.props.item.url.substring(i + 2);
      let videoUrl = 'https://www.youtube.com/embed/'+vidId+'?rel=0&amp;controls=0&amp;showinfo=0';

      return (<div className='video'><iframe src={videoUrl} title="video" frameborder="0" allowfullscreen></iframe></div>);
  }


  deletePost = () => {
    if(confirm(this.props.dataLoad.languageManager.get(486))) {
        this.closeShare();
        this.props.dataLoad.deletePost(this.props.item).then(() => {
            this.onClose();
            this.props.refreshWall();
        });   
    }
  }

  renderImage = ()  => {
    if(this.props.item.isYouTube === true) {
      return this.renderEmbedVideo();
    } else if(this.props.item.imageURL.length > 0) {

        var clickHandler = () => {};       
        if(this.props.item.url.length > 0) {
          clickHandler = this.openOriginalUrl;
        }
        let url = process.env.REACT_APP_BASE_URL+this.props.item.imageURL;
        return (<div className="image" onClick={clickHandler}><img alt="Voir l'original" src={url}></img></div>);
    } else {
      return "";
    }

  }


renderAuthor = (item) => {
  if(this.props.item.parentFeed.isSearch == true || this.props.item.parentFeed.parentFeedType.id == 1) {
      return this.renderAuthorName(this.props.item.authorName);
  }
  return null;
}

renderAuthorName = (authorName) => {
    return (<div className="item-author">{ authorName }</div>);
}

  render() {
      var titleHtml = { __html: "" }; 
      var bodyHtml = { __html: "" };
      var activeClass = "inactive";
      var networkClass = "";
      var campaign = "";
      var footer  ="";
      var shareOverlay = "";
      var comments ="";
      var optionsOverlay ="";
      var author = "";
      if(this.props.item !== null) {
          titleHtml = { __html: unescape(this.props.item.title)};
          bodyHtml = { __html: unescape(this.props.item.body)};
          activeClass= "active";

          var image = this.renderImage();


          var likeCount = "";
          if(this.props.item.likeCounter > 0) {
            likeCount = <span className="count"><i className="fa fa-heart" />{this.props.item.likeCounter}</span>
          }

          var viewCount = "";
          if(this.props.item.viewCounter > 0) {
            viewCount = <span className="count"><i className="fa fa-eye" />{this.props.item.viewCounter}</span>
          }

          var shareCount = "";
          if(this.props.item.shareCounter > 0) {
            shareCount = <span className="count"><i className="fa fa-share-alt" />{this.props.item.shareCounter}</span>
          }

          networkClass = this.props.item.parentFeed.parentFeedType.fontAwesome;

          campaign = this.renderCampaign();

          footer = this.renderFooter();

          shareOverlay = this.renderShareOverlay();

          comments = this.renderComments();

          optionsOverlay = (<ComponentOverlay active={this.state.activeOptions} exit={this.closeOptions}>{ this.renderOptionsOverlay() }</ComponentOverlay>);

          author = this.renderAuthor();

      }

      var finalClass= "item-overlay "+activeClass;

     


      return (
          <div className={finalClass} onDragStart={this.onDrag}>
            <div className="row no-gutters align-items-center" onClick={this.onClose}>
                <div className="main" onClick={this.contentClick}>
                <div className="close"><i onClick={this.onClose} className="fa fa-times-thin"></i></div>
                {shareOverlay}
                {optionsOverlay}
                <div className="overlay-content">
                  
                  {image}
                  {campaign}
                  <div className="body">
                    <div className="header">{likeCount}{viewCount}{shareCount}</div>
                    <div className="content">
                      <div className="social-icon"><i className={networkClass} /></div>
                      <div className="body-content">
                          { author }
                         <h1 className="title" dangerouslySetInnerHTML={ titleHtml }></h1>
                         <p className="text" dangerouslySetInnerHTML={bodyHtml}></p>
                         {comments}
                      </div>
                    </div>
                  </div>
                    <div className="footer">
                      {footer}
                     </div>
                </div>
                </div>
            </div>
          </div>
      );
  }

}

export default ItemOverlay;