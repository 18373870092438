class UserGroupTreeManager {

	constructor(groupList) {
		this.groupList = groupList[0].children;

		this.userGroups = [];
		this.selectedItems = [];
		this.openedItems = [];
		this.masterGroup = groupList[0].children[0];
		this.prepareItems();
	}

	prepareItems = () => {
		let masterGroupLevel = this.getMasterGroupLevel(this.groupList, 0);
		var desiredDisplayGroupLevel = 0;
		if(masterGroupLevel > 0) {
			desiredDisplayGroupLevel = masterGroupLevel - 1;
		}
		
		this.userGroups = this.getGroupsOfLevel(this.groupList, desiredDisplayGroupLevel);//this.findDisplayedGroups(this.groupList, []);//
		this.userGroups.forEach(this.prepareGroup);
	}

	getMasterGroup = () => {
		return this.masterGroup;
	}

	getMasterGroupLevel = (groupsToSearch, currentLevel=0) => {
		for(var i=0; i<groupsToSearch.length;i++) {
			let group = groupsToSearch[i];
			if(group.isMemberPrincipal) {
				this.masterGroup = group;
				return currentLevel;
			} else if(this.hasMasterChild(group)) {
				return this.getMasterGroupLevel(group.children, currentLevel+1);	
			}
		}
		return currentLevel;
	}

	hasMasterChild = (group) => {
		for(var i=0; i<group.children.length; i++) {
			let child = group.children[i];
			if(child.isMemberPrincipal) {
				return true;
			} else if(this.hasMasterChild(child)) {
				return true;
			}
		}
		return false;
	}

	hasSelectableChildren = (group) => {
		for(var i=0; i<group.children.length; i++) {
			let child = group.children[i];
			if(child.isSelectable) {
				return true;
			} else {
				if(this.hasSelectableChildren(child)) {
					return true;
				}
			}
		}
		return false;
	}


	findDisplayedGroups = (groups, foundGroups) => {
		groups.forEach((group) => {
			if(!group.isPrivate) {
				if(group.isSelectable || group.isMemberPrincipal) {
					foundGroups.push(/*this.filterGroupChildren(group)*/group);
				} else if(group.children.length > 0) {
					this.findDisplayedGroups(group.children, foundGroups);
				}
			}
		});
		return foundGroups;
	}

	filterGroupChildren = (group) => {
		if(group.children.length > 0) {
			var newChildren = [];
			group.children.forEach((child) => {
				if(!this.isItemVisible(child)) {
					let savedChildren = child.children;
					savedChildren.forEach()
				} else {
					newChildren.push(child);
				}
				newChildren.push(this.filterGroupChildren(child));
			});

			group.children = newChildren;
		}
		return group;
	}


	getGroupsOfLevel = (groups, searchLevel, currentLevel=0) => {
		var foundGroups = [];
		if(currentLevel === searchLevel) {
			foundGroups = foundGroups.concat(groups);
		} else {
			groups.forEach((group) => {
				if(group.children.length > 0) {
					foundGroups = foundGroups.concat(this.getGroupsOfLevel(group.children, searchLevel, currentLevel+1));
				}
			});
		}

		return foundGroups;

	}

	findMasterGroupParentLevel = () => {
		this.groupList.forEach((group) => { if(group.children.length > 0) {  }})
	}

	findMasterGroup = (groups, level) => {
		let masterGroup = groups.find((group) => {return group.isMemberPrincipal === true});
		if(masterGroup === undefined) {
			for(var i = 0; i < groups.length; i++) {
				let group = groups[i];
				if(group.children.length > 0) {
					let childMasterGroupData = this.findMasterGroup(group.children, level+1);
					if(childMasterGroupData.masterGroup !== undefined) {
						masterGroup = childMasterGroupData.masterGroup;
						level = childMasterGroupData.level;
						break;
					}
				}
			}
		}

		return {masterGroup: masterGroup, level: level};
	}

	prepareGroup = (group) => {
		this.addMembers(group);
		this.addOpened(group)

	}

	hasDirectMemberPrincipalChildren = (group) => {
		return group.children.reduce((hasMember, child) => { return hasMember || child.isMemberPrincipal === true }, false);

	}

	hasMemberChildren = (group) => {
		return group.children.reduce((hasMember, child) => { return hasMember || child.isMember === true || this.hasMemberChildren(child) }, false);

	}

	addOpened = (group) => {
		if(this.hasMemberChildren(group) || this.isPrimaryItem(group) || !this.isItemVisible(group) || this.hasMasterChild(group)) {
			this.openedItems.push(group);
			if(group.children.length > 0) {
				group.children.forEach(this.addOpened);
			}
		}
	}

	addMembers = (group) => {
		if(group.isMember === true) {
			this.selectedItems.push(group);
		}
		if(group.children.length > 0) {
			group.children.forEach(this.addMembers);
		}
	}

	isItemRendered = (item) => {
		return item.isPrivate === false;
	}

	isItemOpened = (item) => {
		return this.openedItems.find((openedItem) => {
			return openedItem.id === item.id;
		}) !== undefined;
	}


	isItemSelected = (item) => {
		return this.selectedItems.find((selectedItem) => {
			return selectedItem.id === item.id;
		}) !== undefined;
	}

	isItemSelectable = (item) => {
		return (item.isSelectable === undefined || item.isSelectable === true) && !item.isMemberPrincipal;
	}


	isPrimaryItem = (item) => {
		return item.isMemberPrincipal;
	}

	isItemVisible = (item) => {
		return item.isMemberPrincipal || item.isSelectable || this.hasSelectableChildren(item);
	}

	onItemSelected = (item) => {
		this.selectedItems.push(item);
	}


	onItemDeselected = (item) => {
		this.selectedItems = this.selectedItems.filter((selectedItem) => {
			return selectedItem.id !== item.id;
		});
	}


	onItemOpened = (item) => {
		this.openedItems.push(item);
	}

	onItemClosed = (item) => {
		this.openedItems = this.openedItems.filter((openedItem) => {
			return openedItem.id !== item.id;
		});

		this.closeChildren(item);
	}

	closeChildren = (item) => {
		if(item.children.length > 0 ) {
			item.children.forEach((child) => {
				this.onItemClosed(child);
			});
		}
	}


	reset = () => {
		this.selectedItems = [];
		this.openedItems = [];
		this.userGroups = [];

		this.prepareItems();
	}


}

export default UserGroupTreeManager;